<template>
    <div class="main">
        <div>
            <template v-for="el in list">
                <uploadImg :src="el.url" @removeSrc="remove(el)" ref="image" :key="el.id"></uploadImg>
            </template>
        </div>
        <i class="el-icon-circle-plus-outline addIcon" @click="add"></i>
    </div>
</template>

<script>
import uploadImg from "@/components/customs/uploadImg.vue";

export default {
    components: { uploadImg },
    props: {
        children: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            list: [
                {
                    url: "",
                    id: Math.random(),
                },
            ],
        };
    },
    created() {},
    methods: {
        getList() {
            return this.$refs.image.map(e => {
                return e.url;
            });
        },
        remove(el) {
            this.list = this.list.filter(e => e.id != el.id);
        },
        add() {
            this.list.push({
                url: "",
                id: Math.random(),
            });
        },
    },
    watch: {
        children: {
            handler(newVal) {
                if (newVal.length) {
                    this.list = newVal.map(e => {
                        return {
                            url: e,
                            id: Math.random(),
                        };
                    });
                } else {
                    this.list = [
                        {
                            url: "",
                            id: Math.random(),
                        },
                    ];
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    position: relative;
    & > div {
        display: flex;
        flex-wrap: wrap;
    }
    .addIcon {
        position: absolute;
        right: 0;
        top: 0;
    }
}
</style>
