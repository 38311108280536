<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>了解威有</el-breadcrumb-item>
                <el-breadcrumb-item>卓越之星</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="_add">
                <button @click="openAdd">添加</button>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="name" align="center" label="名字"> </el-table-column>
                <el-table-column prop="image" align="center" label="缩略图">
                    <template slot-scope="{ row }">
                        <img :src="row.image" style="width: 90%" />
                    </template>
                </el-table-column>
                <el-table-column prop="job" align="center" label="职业">
                    <template slot-scope="{ row }">
                        <span>{{ cha(row.job) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="children" align="center" label="亮点">
                    <template slot-scope="{ row }">
                        <span>{{ row.children.length }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="year_end_bonus" align="center" label="年终奖"> </el-table-column>
                <el-table-column prop="" align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="btns">
                            <div class="_operate">
                                <button @click="openSet(row)">编辑</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)">删除</button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 添加或编辑 -->
        <el-dialog
            :title="`${form.id ? '编辑' : '添加'}卓越之星`"
            :visible.sync="dialogShow"
            width="70%"
            :show-close="false"
            :before-close="bfc"
        >
            <!-- {{ form }} -->
            <el-form :model="form" label-width="80px">
                <el-form-item label="名字">
                    <el-input v-model.trim="form.name"></el-input>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input v-model.trim="form.intro"></el-input>
                </el-form-item>
                <el-form-item label="年终奖">
                    <el-input-number v-model="form.year_end_bonus" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="职业">
                    <el-cascader v-model="form.job" :options="jobList"></el-cascader>
                </el-form-item>
                <el-form-item label="缩略图">
                    <uploadImg :src="form.image" @removeSrc="form.image = ''" ref="image"></uploadImg>
                </el-form-item>
                <el-form-item label="亮点">
                    <Children :children="form.children" ref="children"></Children>
                </el-form-item>
                <el-form-item label="详情">
                    <!-- 富文本 -->
                    <editorVue
                        :txt="form.details"
                        :getTxt="
                            html => {
                                form.details = html;
                            }
                        "
                        :height="500"
                    ></editorVue>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
import uploadImg from "@/components/customs/uploadImg.vue";
import Children from "./children.vue";
import editorVue from "@/components/customs/editor.vue";

export default {
    components: { uploadImg, Children, editorVue },
    data() {
        return {
            tableData: [],
            form: {},
            dialogShow: false,
            jobList: [],
        };
    },
    async created() {
        this.tableData = await this.getData();
        this.getJob();
    },
    methods: {
        // 获取所有员工
        async getData() {
            let { data } = await axios.get("/api/excellent_employees/show");
            return data;
        },
        // 获取所有岗位
        async getJob() {
            let { data } = await axios.get("/api/recruit/show");
            data.forEach(e => {
                e.label = e.title;
                e.value = e.id;
                if (e.children) {
                    e.children.forEach(el => {
                        el.label = el.title;
                        el.value = el.id;
                    });
                }
            });
            this.jobList = data;
        },
        // 打开添加
        openAdd() {
            this.form = {
                name: "",
                year_end_bonus: 0,
                details: "",
                image: "",
                job: ["", ""],
                children: [],
            };
            this.dialogShow = true;
        },
        // 打开编辑
        openSet(row) {
            this.form = {
                ...row,
                job: row.job
                    ? [
                          this.jobList.find(e => {
                              return e.children.find(el => el.id == row.job);
                          }).id,
                          row.job,
                      ]
                    : ["", ""],
                children: row.children.map(e => e.image),
            };
            this.dialogShow = true;
        },
        // 确认
        async yes() {
            let form = { ...this.form };
            form.children = this.$refs.children.getList().filter(e => e != "");
            form.job = form.job[1];
            form.image = this.$refs.image.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/excellent_employees/update", QueryString.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/excellent_employees/add", QueryString.stringify(form));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
                return;
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => true);
            if (!s) return;
            let { data } = await axios.delete("/api/excellent_employees/del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },

        cha(id) {
            let str = "";
            this.jobList.forEach(e => {
                e.children.forEach(el => {
                    if (el.id == id) {
                        str = e.title + " / " + el.title;
                    }
                });
            });
            return str;
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
